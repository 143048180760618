<template>
 <div class="search-form">
  <el-form ref="form" :model="form" inline>
    <el-form-item :label="item.label" v-for="(item, index) in list" :key="index">
      <el-select v-model="form[item.prop]" clearable filterable :placeholder="item.label" allow-create default-first-option @change="change">
        <el-option v-for="(it, i) in options[item.prop]" :key="i" :label="it[item.prop]" :value="it[item.prop]"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button @click="reset">重置</el-button>
    </el-form-item>
  </el-form>
 </div>
</template>

<script>
export default {
  name: 'Form',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    params: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      list: [
        {
          label: '应用',
          prop: 'app'
        },
        // {
        //   label: '主机名',
        //   prop: 'nodename'
        // },
        {
          label: '应用组',
          prop: 'job'
        },
        // {
        //   label: 'Instance',
        //   prop: 'instance'
        // },
        {
          label: 'ip',
          prop: 'ip'
        },
        {
          label: '时间间隔',
          prop: 'interval',
          notInit: true
        }
      ], // 表单项
      form: {},
      options: {}, // 选项对象
      times: [ '30s', '50s', '1m', '2m', '5m', '10m', '30m' ] // 时间间隔
    }
  },
  created() {
    this.init()
  },
  watch: {
    data: {
      handler: function(val) {
        this.form = JSON.parse(JSON.stringify(val))
        this.update(val)
      },
      deep: true
    }
  },
  methods: {
    // 重置
    reset() {
      this.form = {
        interval: '30s'
      }
      this.change()
    },
    // 选择
    change() {
      this.$emit('change', this.form)
    },
    // 初始化下拉选择
    init() {
      this.list.forEach(item => {
        this.$set(this.form, item.prop, '')
        if (item.ontInit) return
        this.getCate('', item.prop)
      })
    },
    // 更新选项
    update(val) {
      this.list.forEach(item => {
        if (val[item.prop]) return
        this.getCate(this.params, item.prop)
      })
    },
    // 获取options
    async getCate(params = '', name) {
      if (name !== 'interval') {
        const res = await this.$api.server.queryCate({ params: params, name: name })
        if (res.code !== 200) return
        this.$set(this.options, name, JSON.parse(res.data).data.result.map(item => ({ ...item.metric })))
      } else {
        this.$set(this.options, name, this.times.map(item => ({
          interval: item
        })))
      }
      this.form = JSON.parse(JSON.stringify(this.data))
    }
  }
}
</script>
